import React, { useState } from "react";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={14}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 14</H1>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>This lesson focused on thinking about risks and benefits when making decisions about health issues. </p>
            <p>Pull up the copies of the rubric you have been working on. What would you add to a rubric about risks and benefits to help you make decisions about health?</p>
            <p>Talk with your teammates about what statements to add to your rubric. Think back to what you did in this lesson to help you. For example, you started the lesson by looking at a commercial for a prescription medicine. You saw that the commercial included both risks and benefits. How would you write that as a statement for your rubric? </p>
            <p>After you write your statements for your rubric, your teacher will lead a class discussion so you can share your ideas with your classmates. </p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>Have students get their rubrics that they have been working with. Their goal is to add criteria to the rubric that relate to benefits and risks. Encourage them to think about what they learned in the lesson and to discuss their ideas with their teammates. </p>
            <p>If students need help getting started, you can remind them that they learned about benefits. Prompt them to write a statement along the lines of "The information clearly describes the benefits of the product or treatment." </p>

            <p>After students have time to write statements in their rubrics, hold a class discussion so they can share their ideas. The following are some ideas that students can include on their rubrics. </p>
            <UnorderedList>
              <li>The benefits of the treatment, medication, or intervention are clearly explained.</li>
              <li>Information about potential risks (and the probability of these risks) is provided.</li>
              <li>The information being analyzed includes information about potential costs (e.g., monetary, time) that may be associated with the intervention.</li>
              <li>The information specifies how a given intervention, treatment, or medication compares with other possible interventions.</li>
            </UnorderedList>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

